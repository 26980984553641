import SenseHeader from "src/themes/sense/components/Header";
import SenseProductCard from "src/themes/sense/components/ProductCard";
import SenseProductsList from "src/themes/sense/components/ProductsList/ProductsList";
import SenseShowCasedProductsList from "src/themes/sense/components/ShowCaseProductsList";
import SenseProductDetail from "src/themes/sense/components/ProductDetail";
import SenseBasePage from "src/themes/sense/pages/BasePage";
import SenseBrandPage from "src/themes/sense/pages/BrandPage";
import SenseProductsPage from "src/themes/sense/pages/ProductsPage";
import SenseDealsPage from "src/themes/sense/pages/DealsPage";
import SenseRecommendedProductsList from "src/themes/sense/elements/RecommendedProductsList";
import SenseCategoryBar from "src/themes/sense/components/CategoryBar";
import SenseBrandHeader from "src/themes/sense/elements/BrandHeader";
import SenseViewAllLink from "src/themes/sense/elements/ViewAllLink";
import {SenseTitle} from "src/themes/sense/elements/Title";
import {IndicaTypeIndicator} from "src/themes/sense/elements/IndicaFlowerTypeIcon";
import {SativaTypeIndicator} from "src/themes/sense/elements/SativaFlowerTypeIcon";
import {HybridTypeIndicator} from "src/themes/sense/elements/HybridFlowerTypeIcon";
import SenseCategorizedProductsBrowser from "src/themes/sense/components/CategorizedProductsBrowser/CategorizedProductsBrowser";
import suspensify from "@ui/utils/suspensify";
import {lazy} from "react";

const SenseModal = suspensify(
  lazy(() =>
    import("src/themes/sense/elements/Modal").then(module => ({
      default: module.SenseModal,
    }))
  )
);

export default {
  inherits: "baseTheme",
  vars: {
    SHOWCASED_PREFETCH_LIMIT: 10,
  },
  components: {
    Modal: SenseModal,
    Header: SenseHeader,
    CategoryBar: SenseCategoryBar,
    ProductCard: SenseProductCard,
    ShowCasedProductsList: SenseShowCasedProductsList,
    ProductsList: SenseProductsList,
    ViewAllLink: SenseViewAllLink,
    ProductDetail: SenseProductDetail,
    CategorizedProductsBrowser: SenseCategorizedProductsBrowser,
    RecommendedProductsList: SenseRecommendedProductsList,
    Title: SenseTitle,
    BrandHeader: SenseBrandHeader,
    IndicaTypeIndicator: IndicaTypeIndicator,
    SativaTypeIndicator: SativaTypeIndicator,
    HybridTypeIndicator: HybridTypeIndicator,
  },
  pages: {
    BasePage: SenseBasePage,
    BrandPage: SenseBrandPage,
    ProductsPage: SenseProductsPage,
    ProductDetailPage: SenseProductsPage,
    DealsPage: SenseDealsPage,
    HomePage: SenseProductsPage,
    AllCategoriesPage: SenseProductsPage,
  },
};
