import React from "react";
import {useTheme} from "styled-components";
import TymberCategoryBar from "@menu/components/TymberCategoryBar";
import styles from "src/themes/baseTheme/components/CategoryBar/styles";
import {
  CardCategoryButton,
  CategoryBar,
  CategoryButton,
  isMobile,
  ScrollCategoryBar,
} from "@ui";
import Link from "src/core/common/components/modules/Link";
import useSite from "src/core/sites/hooks/useSite";

export default function BaseThemeCategoryBar() {
  return <TymberCategoryBar CategoryBarComponent={CategoryBarWrapper} />;
}

const CategoryBarWrapper = props => {
  const theme = useTheme();
  const site = useSite();
  const buttonType = site.getUiConfiguration().categoryBar?.button.type;

  if (buttonType === "card" && !isMobile()) {
    return (
      <ScrollCategoryBar
        {...props}
        showIcon={true}
        fixed={false}
        styles={styles(theme, site, {
          buttonShdow: site.getUiConfiguration().categoryBar.button.shadow,
          overrideTheme: site.getUiConfiguration().categoryBar?.theme,
        })}
        CategoryButtonComponent={props => (
          <CardCategoryButton
            {...props}
            linkProps={{keepQuery: true}}
            LinkComponent={Link}
          />
        )}
      />
    );
  } else {
    return (
      <CategoryBar
        {...props}
        styles={styles(theme, site, {
          buttonShdow: site.getUiConfiguration().categoryBar.button.shadow,
          overrideTheme: site.getUiConfiguration().categoryBar?.theme,
        })}
        CategoryButtonComponent={props => (
          <CategoryButton {...props} linkProps={{keepQuery: true}} LinkComponent={Link} />
        )}
      />
    );
  }
};
