import React from "react";
import {ProductListHeader, SimpleProductsList} from "@ui";
import useDealsPage from "@mock/hooks/useDealsPage";
import styles from "@themes/default/components/DealsList/styles";
import useTheme from "@mock/hooks/useTheme";

export default function DefaultDealsList() {
  const theme = useTheme();
  const {
    deals,
    site,
    DealCardComponent,
    showDealsList,
    dealsListTitle,
    dealsListDescription,
  } = useDealsPage();

  const dealsPerSlide = {
    lg: 6,
    md: 6,
    sm: 6,
  };

  if (!showDealsList) return null;

  return (
    <SimpleProductsList
      styles={styles(theme, site)}
      maxProducts={dealsPerSlide}
      products={deals}
      ProductCardComponent={DealCardComponent}
      HeaderComponent={
        <ProductListHeader title={dealsListTitle} description={dealsListDescription} />
      }
    />
  );
}
