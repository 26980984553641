import React, {useState} from "react";
import styled, {createGlobalStyle} from "styled-components";

export default function AndroidSmartBanner({
  appInfo = {},
  position = "top",
  onClose = () => {},
}) {
  const [show, setShow] = useState(true);
  const {imageUrl, name, linkButtonText, linkUrl} = appInfo;

  return (
    show && (
      <>
        <GlobalStyles />
        <BannerContainer position={position}>
          <CloseButtonContainer>
            <CloseButton
              onClick={() => {
                setShow(false);
                onClose();
              }}
            >
              X
            </CloseButton>
          </CloseButtonContainer>
          <AppImageContainer>
            <AppImage src={imageUrl} />
          </AppImageContainer>
          <AppInfoContainer>
            <AppName>{name}</AppName>
          </AppInfoContainer>
          <AppLinkContainer>
            <AppLinkButton href={linkUrl} rel="noopener noreferrer">
              {linkButtonText}
            </AppLinkButton>
          </AppLinkContainer>
        </BannerContainer>
      </>
    )
  );
}

const GlobalStyles = createGlobalStyle`
  body {
    overflow: scroll;
    padding-top: 100px;
  }
`;

const BannerContainer = styled.div`
  z-index: 9999;
  box-sizing: border-box;
  position: fixed;
  font-family: sans-serif;
  display: grid;
  grid-template-columns: 20px 100px auto 100px;
  top: ${({position}) => (position === "top" ? "0" : "85%")};
  left: ${({position}) => (position === "top" ? "0" : "20px")};
  right: ${({position}) => (position === "top" ? "0" : "20px")};
  width: ${({position}) => (position === "top" ? "100%" : "auto")};
  height: 100px;
  color: #000;
  background: #f2f2f2;
  box-shadow: ${({position}) =>
    position === "top"
      ? "0 1px 2px rgba(0, 0, 0, 0.8) inset, 0 1px 1px rgba(255, 255, 255, 0.3)"
      : "5px 5px 15px 0px rgba(138, 138, 138, 0.6)"};
  padding: 10px;
  border-radius: ${({position}) => (position === "top" ? "0" : "10px")};
`;

const CloseButtonContainer = styled.div`
  justify-self: end;
  align-self: center;
`;

const CloseButton = styled.button`
  border: none;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: subpixel-antialiased;
  border: 0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  color: #b1b1b3;
  background: #efefef;
  cursor: pointer;
`;

const AppImageContainer = styled.div`
  justify-self: start;
  align-self: center;
  padding-left: 10px;
`;

const AppImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 15px;
`;

const AppInfoContainer = styled.div`
  justify-self: start;
  display: flex;
  flex-direction: column;
  padding: 2px;
  justify-content: center;
  color: #0d0d0d;
  font-size: 12px;
`;

const AppName = styled.div`
  margin-bottom: 5px;
  margin-top: 0;
  font-size: 18px;
  font-weight: bold;
`;

const AppLinkContainer = styled.div`
  justify-self: end;
  text-align: right;
  margin-right: 30px;
  height: 100%;
  display: flex;
`;

const AppLinkButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  height: 40px;
  align-self: center;
  min-width: 70px;
  color: #2cb2dc;
  padding: 0;
  background: transparent;
  border-radius: 10px;
  border: 2px solid #2cb2dc;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
`;
