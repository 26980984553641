import React from "react";
import {Text} from "@ui";
import merge from "lodash/merge";
import useTheme from "@mock/hooks/useTheme";
import useShowcaseProductsList from "@mock/hooks/useShowcaseProductsList";
import styles from "@themes/boost/components/Title/styles";

export default function BoostThemeTitle({children, ...props}) {
  const theme = useTheme();
  const {site} = useShowcaseProductsList({});

  return (
    <Text
      {...props}
      text={children}
      styles={merge({}, styles(theme, site), props.styles)}
    />
  );
}
