import React from "react";
import {DealsList} from "@ui";
import styled from "styled-components";
import useTheme from "@mock/hooks/useTheme.js";
import Title from "@themes/default/components/Title";
import get from "lodash/get";
import useDealsPage from "@mock/hooks/useDealsPage";
import SeoHead from "@mock/components/modules/SeoHead";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";

export default function DealsPage({props}) {
  const {deals, loading, siteName} = useDealsPage();
  const theme = useTheme();
  const {BasePage} = useThemeComponents();

  const TitleComponent = () => {
    return <span>Awaiting offers</span>;
  };

  const TextComponent = () => {
    return (
      <>
        <span>No deals are available at the moment!</span>
        <span>Please come back another day for great deals.</span>
      </>
    );
  };

  return (
    <BasePage {...props}>
      <SeoHead />
      <Container styles={theme}>
        <DealsList
          skeleton={loading}
          deals={deals}
          noResultsPlaceholderProps={{
            styles: getNoResultsStyles(theme),
            TitleComponent: TitleComponent,
            TextComponent: TextComponent,
          }}
          headerProps={{
            TitleComponent: Title,
            title: `${siteName} Deals For Everyone`,
            description:
              "Check out all of our current cannabis deals below and find a robust offering of deals and rewards is the best way to say thanks for your loyal support.",
          }}
        />
      </Container>
    </BasePage>
  );
}

const getNoResultsStyles = theme => ({
  logo: {
    backgroundColor: "#fff",
    color: get(theme.v1.colors.primary, "#000"),
  },
  titleText: {
    fontFamily: theme.v1.font.family,
    color: get(theme.v1.colors.primaryText, "#000"),
    fontSize: {
      lg: "20px",
      md: "20px",
      sm: "20px",
    },
  },
  detailText: {
    fontFamily: theme.v1.font.secondaryFamily,
    color: get(theme.v1.colors.primaryText, "#000"),
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "12px",
    },
  },
});

const Container = styled.div`
  margin-top: ${({styles}) => styles.v2.spacing(4)};
`;
