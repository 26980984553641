import React from "react";
import {DealCard} from "@ui";
import styles from "@themes/default/components/DealCard/styles";
import useTheme from "@mock/hooks/useTheme";
import useDealsPage from "@mock/hooks/useDealsPage";

export default function DefaultDealCard(props) {
  const theme = useTheme();
  const {site, loading} = useDealsPage();
  return <DealCard {...props} skeleton={loading} styles={styles(theme, site)} />;
}
