import React from "react";
import {
  ProductListHeader,
  MultiRowProductsList,
  renderNodeOrComponent,
  Loader,
} from "@ui";
import useTheme from "@mock/hooks/useTheme.js";
import styled from "styled-components";
import detailProductListStyles, {
  noResultsPlaceholderStyles,
  subcategoriesStyles,
} from "@themes/default/components/DetailProductsList/styles";
import useDetailProductsList from "@mock/hooks/useDetailProductsList";
import Subcategories from "@themes/default/components/DetailProductsList/Subcategories";

export default function DetailProductsList({
  Header,
  SortFilterInHeaderComponent,
  ...otherProps
}) {
  const {site, title, description, subcategories, ...props} = otherProps;

  const theme = useTheme();

  const HeaderComponent = (() => {
    if (Header) {
      return renderNodeOrComponent(Header);
    } else {
      return (
        <ProductListHeader
          title={title}
          description={description}
          FiltersComponent={SortFilterInHeaderComponent}
          SubcategoriesComponent={
            <Subcategories
              subcategories={subcategories}
              styles={subcategoriesStyles(theme, site)}
            />
          }
        />
      );
    }
  })();

  return (
    <ListContainer>
      <MultiRowProductsList
        {...props}
        styles={detailProductListStyles(theme, site)}
        noResultsPlaceholderProps={{
          styles: noResultsPlaceholderStyles(theme, site),
        }}
        LoadingComponent={CustomLoader}
        HeaderComponent={HeaderComponent}
      />
    </ListContainer>
  );
}
const CustomLoader = () => (
  <LoaderContainer>
    <Loader />
  </LoaderContainer>
);
const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto 60px;
`;

export function DetailProductsListWrapper({Header, SortFilterInHeader}) {
  const listProps = useDetailProductsList();

  return (
    <DetailProductsList
      {...listProps}
      Header={Header}
      SortFilterInHeaderComponent={SortFilterInHeader}
    />
  );
}
