import {useRewardsResource} from "src/state/hooks/useAsyncResource";
import {usePromotionsResource} from "src/state/hooks/useAsyncResource";
import get from "lodash/get";

function useDeals({user}) {
  const {data: rewards, meta: rewardsMeta} = useRewardsResource();
  const {data: promotions, meta: promotionsMeta} = usePromotionsResource();

  const _promotions =
    promotions
      ?.getElements()
      .filter(promo => promo.isFeatured)
      .map(promo => ({
        title: get(promo, "title", ""),
        type: get(promo, "type", ""),
        image: get(promo, "image", ""),
      })) || [];

  const _rewards =
    rewards
      ?.getElements()
      .filter(promo => promo.isFeatured)
      .map(r => ({
        title: get(r, "name", ""),
        type: "reward",
        image: get(r, "image", ""),
      })) || [];

  const deals = _promotions.concat(_rewards);

  const loading = promotionsMeta.loading || rewardsMeta.loading;

  return {
    promotions: _promotions,
    promotionsMeta,
    rewards: _rewards,
    rewardsMeta,
    deals,
    loading,
  };
}

export default useDeals;
