import TymberAPIConfig from "../api/config";
import {v1} from "../api/utils";

const Endpoints = {
  v1: {
    promotions: () => v1(`store/deals/promotions`),
    rewards: () => v1(`store/deals/rewards/`),
  },
};

export function promotions() {
  const axios = TymberAPIConfig.axios();
  return axios.get(Endpoints.v1.promotions()).then(response => response.data);
}

export function rewards() {
  const axios = TymberAPIConfig.axios();
  return axios.get(Endpoints.v1.rewards()).then(response => response.data);
}
