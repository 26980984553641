import React from "react";
import BaseProductsBrowser from "@menu/components/BaseProductsBrowser";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";
import AppliedFiltersSection from "src/themes/baseTheme/elements/filters/AppliedFiltersSection";
import ActionsSortFilter from "src/themes/medleaf/elements/filters/ActionsSortFilter";
import SortFilters from "src/themes/medleaf/elements/filters/SortFilters/SortFilters";

const SearchProductsList = dynamic(() =>
  import("@menu/components/SearchProductsList").catch(
    logModuleLoadError("SearchProductsList", {critical: true})
  )
);
const ShowCaseProductsList = dynamic(() =>
  import("@menu/components/ShowCaseProductsList").catch(
    logModuleLoadError("ShowCaseProductsList", {critical: true})
  )
);
const ProductsList = dynamic(() =>
  import("@menu/components/ProductsList").catch(
    logModuleLoadError("ProductsList", {critical: true})
  )
);

export default function MedLeafCategorizedProductsBrowser({hideFilters}) {
  return (
    <BaseProductsBrowser
      render={({category, subcategories, filters, onListUpdate}) => {
        const hasFilters = filters.hasFilters();
        return (
          <>
            {hasFilters && <AppliedFiltersSection filters={filters} />}

            {!filters.search && filters.category && (
              <>
                {!hideFilters && <ActionsSortFilter filters={filters} showSort={false} />}
                <ProductsList
                  title={category ? category.getName() : ""}
                  onListUpdate={onListUpdate}
                  description={category ? category.getDescription() : undefined}
                  category={category}
                  subcategories={subcategories}
                  filters={filters}
                  SortFilterInHeader={SortFilters}
                />
              </>
            )}
            {!filters.search && !filters.category && !category && (
              <div>
                {!hideFilters && <ActionsSortFilter filters={filters} />}
                <ShowCaseProductsList filters={filters} />
              </div>
            )}

            {filters.search && (
              <div>
                {!hideFilters && <ActionsSortFilter filters={filters} />}
                <SearchProductsList onListUpdate={onListUpdate} filters={filters} />
              </div>
            )}
          </>
        );
      }}
    />
  );
}
