import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import useDeals from "src/core/user/hooks/useDeals";
import useSite from "src/core/sites/hooks/useSite";
import useThemeConfig from "src/themes/useThemeConfig";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import usePages from "src/core/shops/hooks/usePages";

export default function useDealsPage() {
  const config = useThemeConfig();
  const [, authApi] = useAuthentication();
  const toggles = useFeatureToggles();
  const {headerPages} = usePages();
  const {promotions, rewards, deals, loading} = useDeals({
    user: authApi.userProfile(),
  });

  const dealsPage = headerPages
    .map(page => ({
      url: {pathname: page.linkTo},
      label: page.label,
      external: page.external,
      description: page.description,
      slug: page.slug,
    }))
    .find(page => page.slug === "deals");

  const site = useSite();
  const siteName = site.getName()?.replace(/-/g, " ");
  const words = siteName?.split(" ").filter(word => word !== "") || [];

  const capitalizedSiteName = words
    .map(word => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");

  return {
    site,
    showDealsList: toggles.showDealsList(),
    dealsListTitle: dealsPage?.label || "Current Deals",
    dealsListDescription: dealsPage?.description || "Check out all of our current deals",
    loading,
    deals,
    rewards,
    promotions,
    siteName: capitalizedSiteName,
    DealCardComponent: config.components.DealCard,
  };
}
